import { Link } from "gatsby";
import React, { useState } from "react";
import movie from "../../images/movie.mp4";
import logoWhite from "../../images/logo--white.svg";

const Nav = ({ showMenu, setShowMenu }) => (
  <nav>
    <div className="px-6 pt-6 m-auto max-w-default md:px-0">
      <div className="flex items-center justify-between">
        <div className="m-auto sm:m-0 flex items-center">
          <Link to="/">
            {/* <img className="h-5 cursor-pointer" src={saasLogo} alt="Logo" /> */}
            <img className="h-5 cursor-pointer" src={logoWhite} alt="Logo" />
          </Link>
        </div>
        <div className="hidden md:flex items-center gap-6 relative">
          <a href="#features" className="text-white text-opacity-75">
            Features
          </a>
          <a href="#pricing" className="text-white text-opacity-75">
            Pricing
          </a>
          <a
            href="https://app.urlcheckr.com"
            className="inline-flex items-center px-6 py-2 text-white rounded-2xl bg-white bg-opacity-20 filter md:w-auto whitespace-nowrap hover:bg-opacity-40 transition"
          >
            Login
          </a>
        </div>
      </div>
    </div>
  </nav>
);

const Hero2 = () => {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <section className="font-dm-sans bg-mesh bg-cover">
      <Nav setShowMenu={setShowMenu} showMenu={showMenu} />

      <section className="m-auto md:mt-24 px-6 md:px-0 max-w-default pb-12 md:pb-[138px]">
        {/* <div className="flex flex-col text-center"> */}
        <div className="flex flex-col lg:flex-row items-center lg:gap-24 -mb-16 lg:-mb-48">
          <div className="flex flex-col justify-center mt-8 lg:mt-0 text-center lg:text-left mb-16">
            <h1 className="font-medium text-mobile-h1 leading-[48px] md:text-desktop-h1 text-slate-headline max-w-[754px]">
              Bulk url checking made easy.
            </h1>
            <p className="text-slate-headline text-desktop-subheading max-w-[754px] mt-8">
              Urlcheckr helps you analyse server responses, identify
              configuration issues and follow redirect flows.
            </p>
            <div className="mt-12">
              <a
                href="https://app.urlcheckr.com/signup"
                className="bg-slate-blue px-8 py-4 rounded-3xl font-medium text-lg text-white hover:brightness-125 transition whitespace-nowrap"
              >
                Get started for free
              </a>
              <p className="px-8 text-sm text-slate-body text-opacity-60 mt-6">
                No credit card required
              </p>
            </div>
          </div>

          <div className="flex max-w-xl">
            {/* <div className="flex order-1 px-8 mt-6 md:mt-12 md:order-2 bg-cover bg-ct bg-mesh rounded-2xl"> */}
            {/* <img
              src={groupBanner}
              className="pt-4 md:pt-[60px] m-auto -mb-6 md:-mb-12"
              alt=""
            /> */}
            <video autoPlay loop className="shadow-lg rounded-lg mb-auto">
              <source src={movie} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </section>
    </section>
  );
};

export { Hero2 };
