import { Link } from "gatsby";
import React, { useState } from "react";
import logoWhite from "../../images/logo--white.svg";

const Nav = ({ showMenu, setShowMenu }) => (
  <nav>
    <div className="px-6 py-6 m-auto max-w-default md:px-0">
      <div className="flex items-center justify-between">
        <Link to="/">
          {/* <img className="h-5 cursor-pointer" src={saasLogo} alt="Logo" /> */}
          <img className="h-5 cursor-pointer" src={logoWhite} alt="Logo" />
        </Link>
        <div className="flex items-center gap-6">
          <Link
            to="/"
            className="inline-flex items-center px-6 py-2 text-white rounded-2xl bg-white bg-opacity-20 filter md:w-auto whitespace-nowrap hover:bg-opacity-40 transition"
          >
            Back to Urlcheckr
          </Link>
        </div>
      </div>
    </div>
  </nav>
);

const Hero2Dense = () => {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <section className="font-dm-sans bg-mesh bg-cover">
      <Nav setShowMenu={setShowMenu} showMenu={showMenu} />
    </section>
  );
};

export { Hero2Dense };
