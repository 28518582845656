import React from "react";
import { Helmet } from "react-helmet";

const Head = ({ title }) => {
  return (
    <>
      <Helmet
        defaultTitle="Urlcheckr"
        title={title}
        titleTemplate="%s – Urlcheckr"
      />
    </>
  );
};

export default Head;
